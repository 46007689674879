import React, { Component, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons';


class Contact extends Component {
    
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
            <div>
                <div className="contact-header">
                    <h3 style={{fontWeight: 400}}>Холбоо барих</h3>
                    <h1>БИДЭНТЭЙ ДЭЛГЭРЭНГҮЙ ХОЛБОГДОХ ХАЯГУУД</h1>
                </div>
                <div className="contact-body">
                    <div className="contact-item">
                        <div className="icon-container">
                            <span className="icon-mask-lg">
                                <FontAwesomeIcon size="2x" icon={faPhoneAlt}/>
                           </span>
                        </div>
                        <div className="contact-title">Лавлах утас</div>
                        <p>Олон нийттэй харилцах мэдээллийн хэрэгсэл, зар сурталчилгаа болон ивээн тэтгэлэгтэй холбоотой асуудлаар бол</p>
                        <div className="contact-addr">+976 11-324202</div>
                    </div>
                    <div className="contact-item">
                        <div className="icon-container">
                            <span className="icon-mask-lg">
                                <FontAwesomeIcon size="2x" icon={faEnvelope}/>
                            </span>
                        </div>
                        <div className="contact-title">И-мэйл хаяг</div>
                            <p>Бүтээгдэхүүн болон үйл ажиллагаатай холбоотой санал хүсэлт илгээх, тодруулга мэдээлэл авахаар бол</p>
                        <div className="contact-addr">contact@sodcard.mn</div>
                    </div>
                    <div className="contact-item">
                        <div className="icon-container">
                            <span className="icon-mask-lg">
                                <FontAwesomeIcon size="2x" icon={faLocationArrow}/>
                            </span>
                        </div>
                        <div className="contact-title">Хаяг байршил</div>
                        <p>Амарын гудамж, СТӨ В корпус, Сүхбаатар дүүрэг, Улаанбаатар 14200, Монгол улс Сод Монгол Групп</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;