class Language {
    static PAGE_HOME = 'Нүүр';
    static PAGE_NEWS = 'Мэдээ';
    static PAGE_HELP = 'Тусламж';
    static PAGE_CONTACT = 'Холбогдох';
    static PAGE_TERMS = 'Үйлчилгээний нөхцөл';
    static PAGE_DASHBOARD = 'Хянах самбар';
    static PAGE_SETTINGS = 'Тохиргоо';
    static PAGE_POLICY = 'Нууцлалын бодлого';

    static BTN_LOGIN = 'Нэвтрэх';
    static BTN_LOGOUT = 'Гарах';
    static BTN_REGISTER = 'Бүртгүүлэх';
    static BTN_FORGET = 'Нууц үгээ мартсан';
    static BTN_RECOVER = 'Сэргээх';
    static BTN_BACK = 'Буцах';
    static BTN_BUY = 'Худалдаж авах';
    static BTN_TRANSFER = 'Шилжүүлэх'
    static BTN_LOADING = 'Түр хүлээнэ үү ...';
    static BTN_CHANGEINFO = 'Мэдээлэл засах';
    static BTN_CHANGEPASS = 'Нууц үг солих'
    static BTN_SAVE = 'Хадгалах';
    static BTN_CONFIRM = 'Баталгаажуулах';

    static LOGIN_TITLE = 'Нэвтрэх';
    static LOGIN_DESC = 'Та бүртгүүлсэн утасны дугаар болон нууц үгээ бичиж нэвтэрнэ үү!';
    static RECOVER_TITLE = 'Сэргээх';
    static RECOVER_DESC = 'Та бүртгүүлсэн утасны дугаар эсвэл имэйл хаягаа бичээд нууц үгээ сэргээлгэнэ үү!';
    static REGISTER_TITLE = 'Бүртгүүлэх';
    static REGISTER_DESC = 'Та Сод Монгол Группын ШТСтанцаас Сод Карт авсанаар бүртгүүлэх боломжтой';

    static INPUT_PHONE = 'Утасны дугаар';
    static INPUT_PHONE_DESC = 'Утасны дугаар нь 8 оронтой тоо байна';
    static INPUT_PASSWORD = 'Нууц үг';
    static INPUT_PIN_CODE = 'Пин код';
    static INPUT_PIN_CODE_RE = 'Пин код давтах';
    static INPUT_OLD_PASSWORD = 'Хуучин нууц үг';
    static INPUT_NEW_PASSWORD = 'Шинэ нууц үг';
    static INPUT_NEW_RE_PASSWORD = 'Шинэ нууц үг давт';
    static INPUT_PASSWORD_DESC = 'Нууц үг нь 4 оронтой тоо байна';
    static INPUT_REGISTER = 'Регистрийн дугаар';
    static INPUT_REGISTER_DESC = 'Регисртийн дугаар';
    static INPUT_NAME = 'Нэр';
    static INPUT_NAME_DESC = '';
    static INPUT_EMAIL = 'Имейл хаяг';
    static INPUT_EMAIL_DESC = '';
    static INPUT_CONFIRMATION = 'Баталгаажуулах код';
    static INPUT_CONFIRMATION_DESC = 'Таний утасны дугаарт ирсэн 6 оронтой тоог оруулна уу';

    static TITLE_TRANSACTION_HISTORY = 'Гүйлгээний хуулга';
    static TITLE_MAP = 'Газрийн зураг';
    static LABEL_BUY_BONUS = 'Бонус төгрөг худалдаж авах';
    static LABEL_TRANSFER_BONUS = 'Бонус төгрөг шилжүүлэх';
    static LABEL_DRIVER_NAME = 'Содон жолооч';
    static LABEL_CARD_NUMBER = 'Картын дугаар';
    static LABEL_BONUS_TUGRIK = 'Бонус төгрөг';
    static LABEL_BONUS_POINT = 'Бонус оноо';
    static LABEL_FNAME = 'Таны нэр';
    static LABEL_LNAME = 'Таны овог';
    static LABEL_BDAY = 'Таны төрсөн өдөр';
    static LABEL_GANDER = 'Таны хүйс';

    static ERROR_LOGIN_BAD_CREDENTIAL = 'Нэр эсвэл нууц үг буруу байна';
    static ERROR_NETWORK = 'Одоогоор системд нэвтрэх боломжгүй байна';
}

export default Language;