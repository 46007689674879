import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isUserLoggedIn } from '../util/SodUtility';
import NotFound from './NotFound';
import Language from '../language';
import { register, registerConfirm } from '../actions/UserActions';
import * as Type from '../actions/types';
import LoginPop from './popup/LoginPop';
import QRCode from 'qrcode.react';

class Register extends Component {
    
    constructor(props) {
        super(props);

        const {match} = this.props;
        const ref = match.params.ref;

        this.state = {
            isComplete: false,
            isVerify: false,
            phoneNo: "",
            firstName: "",
            pinCode: "",
            rePinCode: "",
            referralCode: ref,
            emailAddress: "",
            confirmNo: "",
            errMessage: "",
            successMessage: ""
        }
        
        this.onRegister = this.onRegister.bind(this);
        this.onVerify = this.onVerify.bind(this);
    }

    render() {
        

        if(isUserLoggedIn()) {
            return <NotFound/>
        }

        // if(this.state.isComplete) {
        //     return <Redirect to="/"/>
        // }

        return(
            <div className="register-container">
                {this.renderRegisterStep()}
                {this.renderVerificationStep()}
                {this.renderSuccessStep()}
            </div>
        );
    }

    onRegister() {
        if(this.validateInputs()) {
            this.props.register({
                phoneNo: this.state.phoneNo,
                firstName: this.state.firstName,
                pinCode: this.state.pinCode,
                rePinCode: this.state.rePinCode,
                referralCode: this.state.referralCode,
                emailAddress: this.state.emailAddress
            });
        } 
    }

    onVerify() {
        if(this.validateConfirmation()) {
            this.props.registerConfirm({
                phoneNo: this.state.phoneNo, 
                confirmNo: this.state.confirmNo
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (nextProps.userStatus !== this.props.userStatus) {
            if(nextProps.userStatus === Type.USER_REGISTERED) {
                this.setState({ isVerify: true });
            } else if(nextProps.userStatus === Type.USER_REGISTER_CONFIRMED) {
                this.setState({ isVerify: false, isComplete: true });
            } else if(nextProps.userStatus === Type.USER_ERROR) {
                this.setState({
                    errMessage: nextProps.userMessage,
                    pinCode: '',
                    rePinCode: '',
                });
            }
        }
        const {match} = nextProps;
        const ref = match.params.ref;
        this.setState({
            referralCode: ref
        })
    }

    validateInputs() {
        if(this.state.phoneNo.length !== 8) {
            this.setState({
                errMessage: 'Зөв утасны дугаар оруулна уу'
            });
            return false;
        }
        if(this.state.firstName.length < 1) {
            this.setState({
                errMessage: 'Нэрээ заавал оруулна уу'
            });
            return false;
        }
        if(this.state.emailAddress < 1) {
            this.setState({
                errMessage: 'Имейл хаяг заавал оруулна уу'
            });
            return false;
        }
        if(this.state.rePinCode !== this.state.pinCode) {
            this.setState({
                errMessage: 'Пин код буруу байна'
            });
            return false;
        }
        return true;
    }

    validateConfirmation() {
        if(this.state.confirmNo.length == 6) {
            return true;
        }
        this.setState({
            errMessage: 'Баталгаажуулах код 6 оронтой байх ёстой'
        })
        return false;
    }

    renderRegisterStep() {
        if(this.state.isVerify) return <div></div>;
        if(this.state.isComplete) return <div></div>

        return (
            <div className="settings-container">
                <div className="password-change-container sod-modal-input-wrapper">
                    {this.renderQRCode()}
                    <div className="sod-input-llabel">{Language.INPUT_PHONE}</div>
                    <input 
                        className="sod-input-large"
                        type="text"
                        value={this.state.phoneNo}
                        onChange={e => this.setState({ phoneNo: e.target.value })} 
                        placeholder={Language.INPUT_PHONE}
                    />
                    <div className="sod-input-llabel">{Language.INPUT_NAME}</div>
                    <input 
                        className="sod-input-large"
                        type="text"
                        value={this.state.firstName}
                        onChange={e => this.setState({ firstName: e.target.value })} 
                        placeholder={Language.INPUT_NAME}
                    />
                    <div className="sod-input-llabel">{Language.INPUT_EMAIL}</div>
                    <input 
                        className="sod-input-large"
                        type="text"
                        value={this.state.emailAddress}
                        onChange={e => this.setState({ emailAddress: e.target.value })} 
                        placeholder={Language.INPUT_EMAIL}
                    />
                    <div className="sod-input-llabel">{Language.INPUT_PIN_CODE}</div>
                    <input 
                        className="sod-input-large"
                        type="password"
                        value={this.state.pinCode}
                        onChange={e => this.setState({ pinCode: e.target.value })} 
                        placeholder={Language.INPUT_PIN_CODE}
                    />
                    <div className="sod-input-llabel">{Language.INPUT_PIN_CODE_RE}</div>
                    <input 
                        className="sod-input-large"
                        type="password"
                        value={this.state.rePinCode}
                        onChange={e => this.setState({ rePinCode: e.target.value })} 
                        placeholder={Language.INPUT_PIN_CODE_RE}
                    />
                    <div style={{color:'red', paddingTop: '20px', paddingBottom: '10px', fontSize: '18px'}}>{this.state.errMessage}</div>
                    <div className="settings-container" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                        <span style={{fontSize:'16px'}} className="warning">{this.state.errorMsg}</span>
                        <span style={{fontSize:'16px'}} className="success">{this.state.successMsg}</span>
                        <button onClick={this.onRegister} className="sod-btn sod-btn-orange sod-btn-large">{Language.BTN_REGISTER}</button>
                    </div>

                </div>
            </div>
        );
    }

    renderQRCode() {
        if(this.state.referralCode == null || this.state.referralCode == undefined) {
            return <div></div>;
        } else {
            return (
                <div className="qr-container">
                    <QRCode value={this.state.referralCode} size="75" width="100" height="100" />
                </div>
            );
        }
    }

    renderVerificationStep() {
        if(!this.state.isVerify) return <div></div>;
        if(this.state.isComplete) return <div></div>

        return (
            <div className="settings-container">
                <div className="password-change-container sod-modal-input-wrapper">
                    <div className="sod-input-llabel">{Language.INPUT_CONFIRMATION}</div>
                    <input 
                        className="sod-input-large"
                        type="text"
                        value={this.state.confirmNo}
                        onChange={e => this.setState({ confirmNo: e.target.value })} 
                        placeholder={Language.INPUT_CONFIRMATION}
                    />
                    <div style={{color:'red', paddingTop: '20px', paddingBottom: '10px', fontSize: '18px'}}>{this.state.errMessage}</div>
                    <div className="settings-container" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                        <span style={{fontSize:'16px'}} className="warning">{this.state.errorMsg}</span>
                        <span style={{fontSize:'16px'}} className="success">{this.state.successMsg}</span>
                        <button onClick={this.onVerify} className="sod-btn sod-btn-orange sod-btn-large">{Language.BTN_CONFIRM}</button>
                    </div>
                </div>
            </div>
        );
    }

    renderSuccessStep() {
        if(!this.state.isComplete) return <div></div>

        return (
            <div className="settings-container">
                <div style={{color:'green', paddingTop: '120px', paddingBottom: '10px', fontSize: '18px'}}>
                    Таний бүртгэл амжилттай үүслээ.
                </div>
                <div className="settings-container" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                    <span style={{fontSize:'16px'}} className="warning">{this.state.errorMsg}</span>
                    <span style={{fontSize:'16px'}} className="success">{this.state.successMsg}</span>
                    <LoginPop/>
                </div>
            </div>
        );
    }
}

const mapState2Props = state => ({
    userStatus: state.user.status,
    userMessage: state.user.message,
    userData: state.user.data,
    successMessage: state.password.successMessage,
    errorMessage: state.password.errorMessage,
});

export default connect(mapState2Props, {register, registerConfirm})(Register);