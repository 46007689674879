import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import TransferReducer from './TransferReducer';
import PasswordReducer from './PasswordReducer';

export default combineReducers({
	user: UserReducer,
	transfer: TransferReducer,
	password: PasswordReducer,
});
