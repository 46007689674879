import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Language from '../../language';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faPaperPlane, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom'
import { transferBalance } from '../../actions/TransactionActions';
import { getTransactionList } from '../../actions/UserActions';
import * as Type from '../../actions/types';
import * as Conf from '../../config';

class BalanceSend extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            amountInput: '0',
            phoneInput: '',
            validationMsg: '',
        }

        this.amountUnit = 2500;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onTransfer = this.onTransfer.bind(this);
        this.onIncreaseAmount = this.onIncreaseAmount.bind(this);
        this.onDecreaseAmount = this.onDecreaseAmount.bind(this);
    }

    componentWillUpdate(nextProps, nextState) {
        console.log('WTF>!>!>!>>!>!>!>!>!>!>!>');
        console.log(nextProps.status);
        console.log(this.props.status);
        if(nextProps.status === 'successful') {
            if(this.props.status === 'successful') {

            } else {
                console.log('SET STATE!');
                this.setState({
                    amountInput: '0',
                    phoneInput: '',
                    validationMsg: '',
                });
                this.props.getTransactionList({
                    bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                    refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                    page: 0,
                    size: 10,
                });
            }
        }
    }
    
    validatePhoneInput(input) {
        if(!isNaN(input)) {
            if(input.toString().length < 9) {
                return true
            }
        }
    }

    onIncreaseAmount() {
        if(this.state.amountInput.length <= 7) {
            this.setState({ amountInput: (parseInt(this.state.amountInput)+this.amountUnit).toString() });
        }
    }

    onDecreaseAmount() {
        if(parseInt(this.state.amountInput) - this.amountUnit >= 0) {
            this.setState({ amountInput: (parseInt(this.state.amountInput-this.amountUnit)).toString() });
        }
    }

    validateAmountInput(input) {
        return true;
    }

    openModal() {
        this.setState({ isOpen: true });
    }

    closeModal() {
        this.setState({ 
            isOpen: false, 
            amountInput: '0',
            phoneInput: '',
            validationMsg: '',
        });
    }

    onTransfer() {
        if(parseInt(this.state.amountInput) > 0) {
            if(this.state.phoneInput.length == 8) {
                this.props.transferBalance({
                    bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                    refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                    phoneNo: this.state.phoneInput,
                    transferAmount: this.state.amountInput,
                });
                this.setState({validationMsg: ''});
            } else {
                this.setState({validationMsg: 'Утасны дугаар 8 оронтой байх ёстой'});
            }
        } else {
            this.setState({validationMsg: 'Шилжүүлэх дүн 0-с их байх ёстой'});
        }
    }

    render() {
        const modalStyle = {
            modal: {
                width: '350px',
                backgroundColor: '#fff',
                padding: '0px',
            }
        };

        const { status, message } = this.props;

        var BUTTON = <div onClick={this.onTransfer} className="transfer-btn">{Language.BTN_TRANSFER}</div>
        var WARNING = <span></span>
        if(status === 'loading') {
            BUTTON = <div className="transfer-btn">{Language.BTN_LOADING}</div>;
        }
        if(this.state.validationMsg !== '') {
            WARNING = <span className="warning">{this.state.validationMsg}</span>
        } else if(message !== '' && status == 'error') {
            WARNING = <span className="warning">{message}</span>
        } else if(message !== '' && status == 'successful') {
            WARNING = <span className="success">{message}</span>
        }

        return (
            <span>
                <div onClick={this.openModal} className="user-transaction-btn">
                    <span className="icon-mask-sm">
                        <FontAwesomeIcon size="lg" icon={faPaperPlane}/>
                    </span>
                    <div style={{width: '10px'}}></div>
                    <div className="user-btn-text">{Language.LABEL_TRANSFER_BONUS}</div>
                </div>
                <Modal
                    open={this.state.isOpen}
                    onClose={this.closeModal}
                    styles={modalStyle}
                >
                    <div className="trasnsfer-container">
                        <div className="icon-container">
                            <span className="icon-mask-lg">
                                <FontAwesomeIcon size="2x" icon={faPaperPlane}/>
                            </span>
                        </div>
                        <div className="transfer-title">Бонус төгрөг шилжүүлэх</div>
                        <p>Өөрийн бонус төргөгийн данснаас бусад хэрэглэгчрүү шилжүүлэх</p>
                        <hr/>
                        <div className="transfer-wrapper">
                            Шилжүүлэх бонус төгрөг
                            <div className="transfer-input-wrapper">
                                <button onClick={this.onIncreaseAmount} className="transfer-round-btn">+</button>
                                <input 
                                    value = {this.state.amountInput}
                                    readOnly
                                    onChange = {e => {
                                        if(this.validateAmountInput(e.target.value)) {
                                            this.setState({ amountInput: e.target.value })}
                                        }
                                    }
                                    type="text"/>
                                <button onClick={this.onDecreaseAmount} className="transfer-round-btn">-</button>
                            </div>
                        </div>
                        <hr/>
                        <div className="transfer-wrapper">
                            Хүлээн авагчийн утасны дугаар
                            <div className="transfer-input-wrapper">
                                <input 
                                    value = {this.state.phoneInput}
                                    onChange = {e => {
                                        if(this.validatePhoneInput(e.target.value)) {
                                            this.setState({ phoneInput: e.target.value });
                                        }
                                    }}
                                    type="text" 
                                    style={{width: '100%'}}/>
                            </div>
                        </div>
                        <hr/>
                        {WARNING}
                    </div>
                    {BUTTON}
                </Modal>
            </span>
        );
    }
}

const mapState2Props = state => ({
    status: state.transfer.status,
    message: state.transfer.message,
});

export default connect(mapState2Props, { transferBalance, getTransactionList })(BalanceSend);