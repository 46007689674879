import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Language from '../../language';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom'
import { login } from '../../actions/UserActions';
import * as Type from '../../actions/types';

class BalanceBuy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            amountInput: '0',
            validationMsg: '',
        }

        this.amountUnit = 2500;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onIncreaseAmount = this.onIncreaseAmount.bind(this);
        this.onDecreaseAmount = this.onDecreaseAmount.bind(this);
    }

    onIncreaseAmount() {
        if(this.state.amountInput.length <= 7) {
            this.setState({ amountInput: (parseInt(this.state.amountInput)+this.amountUnit).toString() });
        }
    }

    onDecreaseAmount() {
        if(parseInt(this.state.amountInput) - this.amountUnit >= 0) {
            this.setState({ amountInput: (parseInt(this.state.amountInput-this.amountUnit)).toString() });
        }
    }

    openModal() {
        this.setState({ isOpen: true });
    }

    closeModal() {
        this.setState({ isOpen: false, amountInput: '0', });
    }

    render() {
        const modalStyle = {
            modal: {
                width: '300px',
                backgroundColor: '#fff'
            }
        };

        return (
            <span>
                <div onClick={this.openModal} className="user-transaction-btn">
                    <span className="icon-mask-sm">
                        <FontAwesomeIcon size="lg" icon={faShoppingCart}/>
                    </span>
                    <div style={{width: '10px'}}></div>
                    <div className="user-btn-text">{Language.LABEL_BUY_BONUS}</div>
                </div>
                <Modal
                    open={this.state.isOpen}
                    onClose={this.closeModal}
                    styles={modalStyle}
                >
                    <div className="trasnsfer-container">
                        <div className="icon-container">
                            <span className="icon-mask-lg">
                                <FontAwesomeIcon size="2x" icon={faShoppingCart}/>
                            </span>
                        </div>
                        <div className="transfer-title">Бонус төгрөг авах</div>
                        <p>Өөрийн бонус төгрөгийн дансаа цэнэглэх</p>
                        <hr/>
                        <div className="transfer-wrapper">
                            Худалдаж авах бонус төгрөг
                            <div className="transfer-input-wrapper">
                                <button onClick={this.onIncreaseAmount} className="transfer-round-btn">+</button>
                                <input 
                                    value = {this.state.amountInput}
                                    readOnly
                                    onChange = {e => {
                                        if(this.validateAmountInput(e.target.value)) {
                                            this.setState({ amountInput: e.target.value })}
                                        }
                                    }
                                    type="text"/>
                                <button onClick={this.onDecreaseAmount} className="transfer-round-btn">-</button>
                            </div>
                        </div>
                        <hr/>
                        <div className="transfer-wrapper">
                            Төлбөр төлөх суваг
                            <div className="transfer-input-wrapper">

                            </div>
                        </div>
                        <hr/>
                    </div>
                </Modal>
            </span>
        );
    }
}

const mapState2Props = state => ({

});

export default connect(mapState2Props, {})(BalanceBuy);