import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';
import routes from "./routes";
import Template from './components/common/Template';

class App extends Component {

  render() {
    return (
      <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
        <Router>
          <Switch>
            <Route
              render={rest => (
                <Template>
                  <Switch>
                    {routes.map(route => (
                      <Route key={route.title} {...route} />
                    ))}
                  </Switch>
                </Template>
              )}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
