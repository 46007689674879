import Home from './components/Home';
import Contact from './components/Contact';
import Help from './components/Help';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Terms from './components/Terms';
import NotFound from './components/NotFound';
import Register from './components/Register';
import Policy from './components/Policy';

export default [
    {
        title: 'Home',
        path: '/',
        exact: true,
        component: Home
    },
    {
        title: 'Help',
        path: '/help',
        exact: true,
        component: Help
    },
    {
        title: 'Contact',
        path: '/contact',
        exact: true,
        component: Contact
    },
    {
        title: 'Terms and Condition',
        path: '/terms',
        exact: true,
        component: Terms
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        exact: true,
        component: Dashboard
    },
    {
        title: 'Settings',
        path: '/settings',
        exact: true,
        component: Settings
    },
    {
        title: 'Register',
        path: '/register',
        exact: true,
        component: Register
    },
    {
        title: 'Register',
        path: '/register&ref=:ref',
        exact: true,
        component: Register
    },
    {
        title: 'Policy',
        path: '/policy',
        exact: true,
        component: Policy
    },
    {
        title: '404',
        component: NotFound
    }
];