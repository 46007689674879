import React, { Component, useContext } from 'react';
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Language from '../../language';

import logoText from '../../assets/image/logo-text.png';

class Footer extends Component {
    render() {
        return (
            <div>
                <div className="footer-container">
                    <div className="sod-container">
                        <img src={logoText}/>
                        <ul className="footer-list">
                            <li><Link to="/">{Language.PAGE_HOME}</Link></li>
                            <li><Link to="/terms">{Language.PAGE_TERMS}</Link></li>
                            <li><Link to="/policy">{Language.PAGE_POLICY}</Link></li>
                            <li><Link to="/contact">{Language.PAGE_CONTACT}</Link></li>
                            <li><span className="footer-space"></span></li>
                            <li><a target="_blank" href="https://www.facebook.com/sodmongolgroup"><FontAwesomeIcon size="lg" icon ={['fab', 'facebook']}/></a></li>
                            <li><a target="_blank" href="https://www.twitter.com/sodmongolgroup"><FontAwesomeIcon size="lg" icon ={['fab', 'twitter']}/></a></li>
                            <li><a target="_blank" href="https://www.youtube.com/sodmongolgroup"><FontAwesomeIcon size="lg" icon ={['fab', 'youtube']}/></a></li>
                            <li><a href="mailto:info@smgroup.mn"><FontAwesomeIcon size="lg" icon ={ faEnvelope }/></a></li>
                        </ul>
                    </div>
                </div>  
                <div className="bottom"></div>
            </div>
        );
    }
}

export default Footer;