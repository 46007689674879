import React, { Component } from 'react';
import Language from '../language';

import appStoreImg from '../assets/image/appstore-icon.png';
import playStoreImg from '../assets/image/playstore-icon.png';
import phoneMockups from '../assets/image/phone-mockups.png';
import RegisterPop from './popup/RegisterPop';

class Home extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                <div className="home-item item1">
                    <h3 style={{fontWeight: 400}}>Сод Монгол Групп</h3>
                    <h1 className="sod-tile">Жолооч нарт зориулсан нэг цэгийн үйлчилгээ</h1>
                    <RegisterPop/>
                </div>
                <div className="home-item item2">
                    <div className="box2">
                        <h2 className="sod-tile">Сод Карт</h2>
                        <p>Сод Монгол Группын ШТС - аар үйлчлүүлэгч жолооч нарт зориулсан шатахууны үнийн хямдрал болон урамшуулалд хамрагдах карт юм.</p>
                    </div>
                </div>
                <div className="home-item item3">
                    <div className="box3">
                        <h2 className="sod-tile">Та Сод Картаар</h2>
                        <ul>
                            <li>Бонус оноо цуглуулж шатахуун авах</li>
                            <li>СОД МОНГОЛ ШТС-н дурын салбараар үйлчлүүлэх</li>
                            <li>Гэрээтэй үйлчилгээний байгууллагын төлбөр төлөх</li>
                            <li>Бонус төгрөг шилжүүлэх болон худалдаж авах</li>
                            <li>Шатахууны хэрэглээгээ байнга хянах боломжтой</li>
                        </ul>
                    </div>
                </div>
                <div className="home-item item4">
                    <div className="box4-phone"></div>
                    <div className="box4">
                        <h2 className="sod-tile">Сод карт аппликэйшн</h2>
                        <p>Та энэхүү аппликэйшнээр машиныхаа шатахууны хэрэглээг хянах, урамшуулалт аянд хамрагдах, үнийн хямдралтай мэдээлэл авахаас гадна бонус төгрөгөөрөө худалдан авалт хийх боломжтой.</p>
                        <div>
                            <a target="_blank" href="https://apps.apple.com/us/app/sod-card/id1381226104">
                                <button style={{marginRight: '15px', marginTop: '10px'}} className="sod-btn sod-btn-orange sod-btn-medium hvr-grow">
                                    <img src={appStoreImg}/>
                                </button>
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=mn.anomaly.sodcard">
                                <button style={{marginTop: '10px'}} className="sod-btn sod-btn-orange sod-btn-medium hvr-grow">
                                    <img src={playStoreImg}/>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="home-item item5">
                    <h3>Лояалти Хөтөлбөр</h3>
                    <h1 className="sod-tile">Та яг одоо сод картаа захиалаарай!</h1>
                </div>
            </div>
        )
    }
}

export default Home;