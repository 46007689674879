import React, { Component, useContext } from 'react';

class Terms extends Component {
    
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
            <div>
                <div className="terms-header">
                    <h1 className="sod-tile">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h1>
                </div>
                <div className="terms-container">
                    <div className="help-body">
                        <h2>НИЙТЛЭГ ҮНДЭСЛЭЛ</h2>
                        <ol>
                            <li>Энэхүү нөхцөл нь www.sodcard.mn вэб сайт болон “SodCard” гар утасны аппликейшн ашиглах, урамшууллын бараа бүтээгдэхүүн худалдан авалт хийхтэй холбоотой үүсэх харилцааг зохицуулна. </li>
                            <li>Энэхүү нөхцөл нь хэрэглэгч худалдан авалт хийх, вэб сайт, гар утасны аппликейшнд бүртгүүлэн ашиглахаас өмнө уншиж танилцан, хүлээн зөвшөөрч, баталгаажуулсны үндсэн дээр хэрэгжинэ. </li>
                            <li>Энэхүү нөхцлийн хэрэгжилтэнд “sodcard” хөтөлбөрийг хэрэгжүүлэгч “Сод Монгол Групп” ХХК /цаашид компани гэх/ болон Хэрэглэгч хамтран хяналт тавина. </li>
                        </ol>
                        <h2>НЭГ. Талуудын эрх, үүрэг </h2>
                        <ol>
                            <li>Энэхүү вэб сайт, гар утасны аппликейшн нь Компанийн өмч бөгөөд лого, нэр бусад загварыг хуулах, олшруулах, дуурайх, өөр бусад ямар ч зүйлд ашиглахыг хориглоно. </li>
                            <li>www.sodcard.mn вэб сайт, “SodCard” гар утасны аппликейшн нь хэрэглэгчдэд компанийн мэдээллийг өгөх болон интернетээр худалдаа явуулах зорилготой байнгын ажиллагаатай вэб сайт, гар утасны аппликейшн байна. </li>
                            <li>Вэб сайт, гар утасны аппликейшнийн хөтлөлт, түүний аюулгүй байдал, вэб сайтын хэрэглэгчдийн мэдээллийн нууцлалыг Компани бүрэн хариуцна. </li>
                            <li>Компани вэб сайт болон гар утасны аппликейшнийн мэдээллийн шинэчлэл, үнийн өөрчлөлт, хөнгөлөлт, урамшуулал болон шинэ үйлчилгээний талаарх мэдээллийн үнэн зөвийг бүрэн хариуцна. </li>
                            <li>Хэрэглэгчийн мэдээллийн бүрэн, аюулгүй, үнэн зөв байдлыг компани цаг тухай бүрт хянаж шалгаж байна. Мэдээллийн нууцлалыг компани бүрэн хариуцна. Хэрэглэгчийн оруулсан мэдээллийг зөвхөн хэрэглэгчтэй байгуулсан тусгай нөхцлөөр буюу хэрэглэгчийн хүсэлтэнд хариулах, үйлчилгээнд шинэчлэл хийхэд анхааруулах болон хэрэглэгчтэй холбоо барихад ашиглана. </li>
                            <li>Хэрэглэгч гуравдагч этгээдэд нэвтрэх нэр, нууц үгээ дамжуулахгүй байх, бүртгэлээсээ гарч браузерээ хаах, аппликейшнээр нэвтрэн орсон үедээ гар утсаа хэн нэгэнд дамжуулахгүй байх замаар өөрийн мэдээллийн аюулгүй байдлыг өөрөө хариуцна. </li>
                            <li>Хэрэглэгчийн мэдээллийн үнэн зөв, бодит байдалд хэрэглэгч бүрэн хариуцлага хүлээнэ. </li>
                            <li>Хэрэглэгч өөрийн сайн дураар www.sodcard.mn вэб сайт болон “SodCard” гар утасны аппликейшнд хувийн мэдээллийг бүртгүүлнэ. </li>
                            <li>Хэрэглэгчийн өөрийн оруулсан мэдээллийн үнэн зөв, бодит байдалд бүрэн хариуцлага хүлээнэ. </li>
                            <li>Компани нь эрх бүхий хууль хяналтын байгууллагын албан ёсны шаардлагаас бусад ямар ч нөхцөлд мэдээллийн нууцыг бусдад дамжуулахгүй чандлан хадгална. </li>
                        </ol>
                        <h2>ХОЁР. Гишүүнчлэл </h2>
                        <ol>
                            <li>Хэрэглэгч www.sodcard.mn вэбсайт болон SodCard гар утасны аппликейшнд бүртгүүлж гишүүн болсноор өөрийн онлайн дансны SodCard-ийг удирдах (үлдэгдэл шалгах, хуулга харах, SodPoint худалдаж авах, шилжүүлэх), урамшууллын бараа бүтээгдэхүүн, үйлчилгээ худалдан авах эрхтэй болно. </li>
                            <li>Хэрэглэгч www.sodcard.mn вэб сайт болон SodCard гар утасны аппликейшн ашиглан гишүүнээр бүртгүүлэхийн тулд өөрийн гар утасны дугаарыг оруулна. </li>
                            <li>Хэрэглэгч бүртгүүлсний дараа баталгаажуулалт хийснээр албан ёсны гишүүн болно. </li>
                            <li>Хэрэглэгч гар утасны дугаараар гишүүн болох эрхтэй. Нэг гар утасны дугаараар нэгээс олон хэрэглэгч ашиглан гишүүн болох боломжгүй. </li>
                            <li>Вэб сайт болон аппликейшнээр хэрэглэгч бүртгүүлэн гишүүн болсноор онлайн данс үүснэ. </li>
                            <li>Хэрэглэгч буруу эсвэл худал мэдээлэл оруулах, гишүүнийхээ үүргийг биелүүлээгүй зэрэг үйлдэл гаргавал тухайн гишүүний дансыг хаана. </li>
                            <li>SodCard хөтөлбөрийн НӨАТУС баримтыг зөвхөн хувь хүнээр өгнө. Байгууллагын НӨАТУС баримт авсан тохиолдолд SodPoint орохгүй. </li>
                        </ol>
                        <h2>ГУРАВ. SodPoint онооны систем </h2>
                        <ol>
                            <li>“Сод Монгол Групп” ХХК-ийн Улаанбаатар хот, орон нутаг дахь шатахуун түгээх станц, “Сод Сүлжээ” дэлгүүрээс дурын банкны төлбөрийн картаар худалдан авалт хийсэн хэрэглэгчийн гүйлгээний 1 төгрөг тутамд 1 SodPoint хуримтлагдана. (1₮ = 1 SodPoint) </li>
                            <li>www.sodcard.mn вэб сайт болон “SodCard” гар утасны аппликейшн дээр банкны картаа бүртгүүлснээр тухайн картын худалдан авалтаар цуглуулсан оноогоо хянах боломжтой болно.</li>
                            <li>Банкны картын гүйлгээ болон бэлэн мөнгөний худалдан авалтанд SodPoint цуглуулах боломжтой. SodPoint хэрэглэгчийн дансанд орсон өдрөөсөө эхлэн 12 сарын хугацаанд хүчинтэй байна. 12 сарын хугацаа нь дууссан SodPoint хэрэглэгчийн данснаас автоматаар хасагдана. </li>
                            <li>Хэрэглэгч цуглуулсан хүрэлцээт SodPoint-оор урамшууллын бараа бүтээгдэхүүн, үйлчилгээ худалдан авалт хийх боломжтой. </li>
                            <li>Эрх бүхий гүйлгээ хийгдсэнээс хойш ажлын 72 цагийн дотор SodPoint хэрэглэгчийн дансанд орно. </li>
                        </ol>
                        <h2>ДӨРӨВ. Интернет худалдаа </h2>
                        <ol>
                            <li>Компани www.sodcard.mn вэб сайт болон SodCard гар утасны аппликейшнээр дамжуулан урамшууллын бараа бүтээгдэхүүн, үйлчилгээг худалдаална. </li>
                        </ol>
                        <h2>ТАВ. Төлбөр тооцоо </h2>
                        <ol>
                            <li>www.sodcard.mn вэбсайт болон SodCard гар утасны аппликейшнээр худалдаалах бараа бүтээгдэхүүн, үйлчилгээний үнэ болон хийгдэх төлбөр тооцоо нь Монгол улсын мөнгөн тэмдэгт “төгрөг”-өөр болон худалдан авагчийн цуглуулсан “SodPoint”-оор хийгдэнэ. </li>
                            <li>Хэрэглэгч www.sodcard.mn вэбсайт болон SodCard гар утасны аппликейшнээс худалдан авалт хийхдээ 100% төлбөрөө төлсөн байна. </li>
                            <li>Хэрэглэгчийн худалдан авах бараа бүтээгдэхүүн, үйлчилгээний үнийн дүнгийн 100% нь Улаанбаатар хот, орон нутаг дахь шатахуун түгээх станц, “Сод Сүлжээ” дэлгүүрүүдээс банкны картаар худалдан авалт хийж цуглуулсан “SodPoint” + бусдаас шилжүүлэн авах “SodPoint” байна. </li>
                        </ol>
                        <h2>ЗУРГАА. Бараа бүтээгдэхүүн, үйлчилгээ хүлээн авалт </h2>
                        <ol>
                            <li>Хэрэглэгч худалдан авсан бараа бүтээгдэхүүн, үйлчилгээгээ “Сод Монгол Групп” ХХК-ийн Улаанбаатар хот, орон нутаг дахь шатахуун түгээх станц, “Сод Сүлжээ” дэлгүүрүүдээс очиж өөрийн биеэр хүлээн авна. </li>
                            <li>Бараа бүтээгдэхүүн, үйлчилгээгээ очиж авахдаа аппликейшн болон вэб дээр худалдан авсан барааныхаа бар код эсвэл бар кодын доор харгалзах кодыг шалгуулан бараагаа хүлээн авна. </li>
                        </ol>
                        <h2>ДОЛОО. Бусад зүйл </h2>
                        <ol>
                            <li>Үйлчилгээний нөхцөлд компани өөрчлөлт оруулах эрхтэй бөгөөд гишүүн үйлчилгээний нөхцлийн хамгийн сүүлийн хувилбарыг уншиж байх үүрэгтэй. </li>
                            <li>Хэрэглэгчийн мэдээлэл болон төлбөрийн талаар ямар нэгэн асуудал эсвэл тодруулга гарвал вэб сайт болон гар утасны аппликейшний санал хүсэлтийн хэсэг эсвэл Хэрэглэгчийн лавлах утас 11-313335 дугаарт холбогдон асуудлуудаа шийдүүлнэ. Асуудлыг 48 цагийн дотор шийднэ. </li>
                            <li>Хэрэглэгчээс гарсан гомдол, маргаантай асуудлыг вэб сайт, гар утасны аппликейшнийн үйлчилгээний нөхцөл, нууцлалын баталгаа болон Монгол улсын хууль дүрмийн дагуу шийдвэрлэнэ. </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;