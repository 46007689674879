import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { isUserLoggedIn, IMAGE_BUFFER } from '../../util/SodUtility';
import { logout, resetStatus } from '../../actions/UserActions';
import * as Type from '../../actions/types';
import Language from '../../language';
import { Link, withRouter, Redirect } from "react-router-dom";
import LoginPop from '../popup/LoginPop';

import navLogo from '../../assets/image/logo.png';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponsiveBtnOpen: false,
            isDropdownOpen: false,
        }

        this.onResponsiveBtn = this.onResponsiveBtn.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }

    onResponsiveBtn () {
        this.setState({isResponsiveBtnOpen: !this.state.isResponsiveBtnOpen});
    }

    onLogout() {
        this.props.logout();
    }

    render() {
        if(this.props.userStatus === Type.USER_LOGOUT) {
            this.props.resetStatus();
            return <Redirect to='/'/>
        } else if(this.props.userStatus === Type.USER_AUTHED) {
            return <Redirect to='/dashboard'/>
        }

        const {pathname} = this.props.location;
        var isHomeActive = '';
        var isHelpActive = '';
        var isContactActive = '';

        if(pathname == '/') {
            isHomeActive = 'active';
        } else if(pathname == '/help') {
            isHelpActive = 'active';
        } else if(pathname == '/contact') {
            isContactActive = 'active';
        } else if(pathname == 'terms') {
              
        }

        return (
            <div className="navigation-bar">
                <div className="navigation-container">
                    <img src={navLogo}/>
                    <ul>
                        <li><Link to="" className={`${isHomeActive}`}>{Language.PAGE_HOME}</Link></li>
                        <li><Link to="/help" className={`${isHelpActive}`}>{Language.PAGE_HELP}</Link></li>
                        <li><Link to="/contact" className={`${isContactActive}`}>{Language.PAGE_CONTACT}</Link></li>
                    </ul>
                    {this.renderUserSection()}
                </div>
            </div>
        );
    }

    renderUserSection() {
        if(isUserLoggedIn()) {
            return this.renderUserLoggedIn();
        } else {
            return this.renderUserNotLoggedIn();
        }
    }

    renderUserLoggedIn() {
        return (
            // <div onClick={this.toggleDropdown} className="user-section">
            //     <div className="user-header-avatar"></div>
            //     {this.renderDropdown()}
            // </div>
            <div></div>
        );
    } 

    renderDropdown() {
        if(this.state.isDropdownOpen) {
            return (
                <div className="user-dropdown">
                    <Link to="/dashboard">
                        <div className="user-dropdown-item">
                            {Language.PAGE_DASHBOARD}
                        </div>
                    </Link>
                    <Link to="/settings">
                        <div className="user-dropdown-item">
                            {Language.PAGE_SETTINGS}
                        </div>
                    </Link>
                    <a href="#" onClick={this.onLogout}>
                        <div className="user-dropdown-item">
                            {Language.BTN_LOGOUT}
                        </div>
                    </a>
                </div>
            );
        } else {
            return null;
        }
    }

    renderUserNotLoggedIn() {
        return (
            <span>
                {/* <LoginPop/> */}
            </span>
        );
    }
}

const mapState2Props = state => ({
    userStatus: state.user.status,
});

export default connect(mapState2Props, { logout, resetStatus })(withRouter(Header));