import * as Type from '../actions/types';

const INITIAL_STATE = {
    successMessage: '',
    errorMessage: '',
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case Type.PASS_LOADED:
            return { successMessage: action.payload }
        case Type.PASS_LOADING:
            return state;
        case Type.PASS_ERROR:
            return { errorMessage: action.payload }
        case 'reset':
            return INITIAL_STATE;
        default: return state;
    }
}