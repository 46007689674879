import * as Type from '../actions/types';

const INITIAL_STATE = {
    status: '',
    message: '',
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case Type.TRANSFER_LOADING:
            return { status: 'loading', message: action.payload }
        case Type.TRANSFER_SUCCESSFUL:
            return { status: 'successful', message: action.payload }
        case Type.TRANSFER_ERROR:
            console.log("WTF", action.payload);
            return { status: 'error', message: action.payload }
        case 'reset':
            return INITIAL_STATE;
        default: return state;
    }
}