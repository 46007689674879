import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import Language from '../language';
import { getMe, cardInfo, getImage, getTransactionList, getFuelStations } from '../actions/UserActions';
import { isUserLoggedIn, IMAGE_BUFFER } from '../util/SodUtility';
import * as Type from '../actions/types';
import NotFound from '../components/NotFound';
import * as Conf from '../config';
import Map from 'pigeon-maps';
import Marker from 'pigeon-marker/react';
import RewardIcon from '../assets/image/reward-icon.svg';
import ReceivedIcon from '../assets/image/received-icon.svg';
import SpentIcon from '../assets/image/spent-icon.svg';

import BalanceSend from '../components/popup/BalanceSend';
import BalanceBuy from '../components/popup/BalanceBuy';


class Dashboard extends Component {

    transactionPage = 0;
    transactionSize = 10;

    constructor(props) {
        super(props);
        this.state = {
            zoom: 13,
        }

        // var transactionPage = 0;
        // var transactionSize = 10;

        if(isUserLoggedIn()) {
            this.props.getMe({ 
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN)
            });
            this.props.cardInfo({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN)
            });
            this.props.getTransactionList({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                page: this.transactionPage,
                size: this.transactionSize,
            });
            this.props.getFuelStations({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
            });
        }
        var userName = '';
        var userCardNo = '000';
        var userBalance = 0;
        var userPoint = 0;
        this.onNextList = this.onNextList.bind(this);
        this.onPrevList = this.onPrevList.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.userStatus === Type.USER_LOADED) {
            this.userName = `${nextProps.userData.lastname.charAt(0)}.${nextProps.userData.firstname}`;
            this.userCardNo = nextProps.userData.cardNo;
            return true;
        }
        if(nextProps.userStatus === Type.USER_CARD_LOADED) {
            this.userBalance = nextProps.cardData.currentBalance;
            this.userPoint = nextProps.cardData.currentLoyaltyPoints;
            return true;
        }
        if(nextProps.transactionStatus === Type.USER_TRANSCATION_LOADED || nextProps.transactionStatus === Type.USER_TRANSACTION_LOADING) {
            return true;
        }
        return false;
    }

    onPrevList() {
        console.log('prev');
        if(this.transactionPage > 0) {
            this.transactionPage--;
            this.props.getTransactionList({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                page: this.transactionPage,
                size: this.transactionSize,
            });
        }
    }

    onNextList() {
        console.log('next');
        console.log(this.props.transactionList.length);
        console.log(this.transactionSize);
        if(this.props.transactionList.length == this.transactionSize) {
            console.log('ETOGG');
            this.transactionPage++;
            this.props.getTransactionList({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                page: this.transactionPage,
                size: this.transactionSize,
            });
        }
    }

    render() {
        console.log(this.props.transactionStatus);
        if(!isUserLoggedIn()) {
            return <NotFound/>
        }

        const cardData = this.props.cardData;
        const userData = this.props.userData;
        const transactionList = this.props.transactionList;
        const fuelstationList = this.props.fuelstationList;
        const imgSrc = this.props.imgSrc;

        const position = [this.state.lat, this.state.lng];

        return (
            <div>
                <div className="dashboard-header">
                    <div className="user-info-container">
                        <div className="user-avatar">
                            {/* <img src={IMAGE_BUFFER} /> */}
                        </div>
                        <div style={{marginLeft: "15px"}}>
                            <div className="user-context-container">
                                <div className="user-context-value">{this.userName}</div>
                                <div className="user-context-label">{Language.LABEL_DRIVER_NAME}</div>
                            </div>
                            <div className="user-context-container">
                                <div className="user-context-value">{this.userCardNo}</div>
                                <div className="user-context-label">{Language.LABEL_CARD_NUMBER}</div>
                            </div>
                        </div>
                    </div>
                    <div className="user-bonus-container">
                        <div className="user-context-container">
                            <div className="user-context-value">{this.userBalance}</div>
                            <div className="user-context-label">{Language.LABEL_BONUS_TUGRIK}</div>
                        </div>
                        <div className="user-context-container">
                            <div className="user-context-value">{this.userPoint}</div>
                            <div className="user-context-label">{Language.LABEL_BONUS_POINT}</div>
                        </div>
                    </div>
                    <div className="user-transaction-btn-container">
                        <BalanceBuy/>
                        <BalanceSend/>
                    </div>
                </div>
                
                <div className="dashboard-body">
                    <div className="dashboard-card-container transaction-list-container">
                        <div className="card-title">{Language.TITLE_TRANSACTION_HISTORY}</div>
                        <div className="card-body">
                            {this.renderTransactionList(transactionList)}
                        </div>
                    </div>
                    <div style={{width: '15px', height: '15px'}}> </div>
                    <div className="dashboard-card-container map-container">
                        <div className="card-title">{Language.TITLE_MAP}</div>
                        <div className="card-body">
                            <Map center={[47.921230, 106.918556]} zoom={12} height={750}>
                                {/* <Marker anchor={[50.874, 4.6947]} payload={1} onClick={({ event, anchor, payload }) => {}} />
                                    <Overlay anchor={[50.879, 4.6997]} offset={[120, 79]}>
                                </Overlay> */}
                                {fuelstationList.map((station, key) => {
                                    return this.renderFuelStationMarker(station, key);
                                })}
                            </Map>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFuelStationMarker(obj, key) {
        const lat = parseFloat(obj.latitude);
        const lon = parseFloat(obj.longitude);
        return (
            <Marker anchor={[lat, lon]} payload={1} onClick={({ event, anchor, payload }) => {
                console.log(obj);
            }} />
        );
    }

    renderTransactionList(list) {
        if(this.props.transactionStatus === Type.USER_TRANSACTION_LOADING) {
            return (
                <div className="ring-center">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            );
        }
        return (
            <div>
                {list.map((transaction, key) => {
                    return this.renderTransactionItem(transaction, key);
                })}
                <div className="page-navigator">
                    <div>
                        <button className="hvr-skew-forward" onClick={this.onPrevList}>«</button>
                        <button className="hvr-skew-backward" onClick={this.onNextList}>»</button>
                    </div>
                </div>
            </div>
        );


        
    }

    renderTransactionItem(item, index) {
        var color = '#fff';
        if(index & 1 == 1) {
            color = '#fffaf7';
        }
        return (
            <div className="list-item-container" style={{backgroundColor: color}}>
                <div className="list-item-head">
                    {this.renderTransactionIcon(item.historyType)}
                </div>
                <div className="list-item-body">
                    <div className="list-item-body-head">
                        <div className="list-item-title">{item.title}</div>
                        <div className="list-item-tail">{item.stringDate}</div>
                    </div>
                    <div>{item.body}</div>
                </div>
            </div>
        );
    }

    renderTransactionIcon(type) {
        var source = ReceivedIcon;
        if(type === 'transferout') {
            source = SpentIcon;
        } else if(type === 'transaction') {
            source = RewardIcon;
        }
        return(
            <span><img src={source}/></span>
        );
    }
}

const mapState2Props = state => ({
    userStatus: state.user.status,
    transactionStatus: state.user.transactionStatus,
    userData: state.user.data,
    userMessage: state.user.message,
    imgSrc: state.user.imgSrc,
    cardData: state.user.cardData,
    notificationList: state.user.notificationList,
    transactionList: state.user.transactionList,
    fuelstationList: state.user.fuelstationList,
});

export default connect(mapState2Props, { getMe, cardInfo, getImage, getTransactionList, getFuelStations })(Dashboard);