import React, { Component, useContext } from 'react';

class Help extends Component {
    
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
            <div>
                <div className="help-header">
                    <h3 style={{fontWeight: 400}}>Түгээмэл асуултууд</h3>
                    <h1 className="sod-tile">ТАНД ЯМАР ТУСЛАМЖ ХЭРЭГТЭЙ БАЙНА?</h1>
                </div>
                <div className="help-container">
                    <div className="help-nav">
                        <ol>
                            <li><a href="/#/help#p1">Содкарт гэж юу вэ?</a></li>
                            <li><a href="/#/help#p2">Содкартаар худалдан авалт хийхэд шатахууны үнэ хямдрах уу?</a></li>
                            <li><a href="/#/help#p3">Бонус карт хэрхэн нээлгэх вэ?</a></li>
                            <li><a href="/#/help#p4">Бэлэн болон бэлэн бус банкны картаар худалдан авалт хийхэд бонус оноо бодогдох уу?</a></li>
                            <li><a href="/#/help#p5">Бонус оноо хэрхэн худалдаж авах вэ?</a></li>
                            <li><a href="/#/help#p6">Бонус оноогоо бусдад шилжүүлж болох уу?</a></li>
                            <li><a href="/#/help#p7">Нууц үгээ хэрхэн солих</a></li>
                        </ol>
                    </div>
                    <div className="help-body">
                        <h2 id="p1">Содкарт гэж юу вэ?</h2>
                        <p>Сод Монгол Группын шатахуун түгээх станцуудаас хэрэглэгч Содкартаа ашиглан худалдан авалт хийх бүрт хэрэглэгчийн дансыг бонус оноогоор цэнэглэх болно. Хэрэглэгч хуримтлуулсан бонус төгрөгөөрөө дараа худалдан авалтаасаа хасалт хийлгэх замаар хөнгөлөлт эдлэх боломжтой.</p>
                        <h2 id="p2">Содкартаар худалдан авалт хийхэд шатахууны үнэ хямдрах уу?</h2>
                        <p>Сод Монгол группээс жилийн зарим саруудад Содкартын хэрэглэгч нар тусгайлан шатахууны хямдралт өдрүүд зарлах болно. Тухайн урамшуулал бүрийг хэрэглэгчийн бүртгүүлсэн гар утасны дугаар болон мобайл АПП-аар мэдээлэл болон түгээх болно. Хямдрал эхлэх үед жолооч та Содкартаа уншуулснаар тухайн худалдан авах шатахууны нэгжийн үнийг хямдруулж авах боломжтой болно.</p>
                        <h2>Бонус карт хэрхэн нээлгэх вэ?</h2>
                        <p>Та өөрт ойрхон Сод монгол группын шатахуун түгээх станцад хандан газар дээр нь нээлгэж авах боломжтой.</p>
                        <h2> Бэлэн болон бэлэн бус банкны картаар худалдан авалт хийхэд бонус оноо бодогдох уу?</h2>
                        <p>Хэрэглэгч картын дансандаа үлдэгдэлгүй тохиолдолд Содкартаа уншуулж бонус оноогоо цуглуулж авах боломжтой байгаа. Өөрөөр хэлбэл та бэлэн мөнгөөр тооцоо хийсэн ч бонус оноогоо өөрийн бүртгэл дээр нэгтгэж авна</p>
                        <h2>Бонус оноо хэрхэн худалдаж авах вэ?</h2>
                        <p>Жолооч та өөрт ойрхон Сод монгол группын шатахуун түгээх станцад хандан картын дансаа цэнэглэх боломжтойгоос гадна www.sodcard.mn сайтаар болон гар утасны апп-аар өөрийн эрхээрээ нэвтэрч дансаа цэнэглэх боломжтой.</p>
                        <h2>Бонус оноогоо бусдад шилжүүлж болох уу?</h2>
                        <p>www.sodcard.mn сайтаар болон гар утасны апп-аар та хүлээн авах хэрэглэгчийн утасны дугаарыг оруулж шилжүүлэх үнийн дүнг оруулсанаар бонус оноогоо бусдад бэлэг болгон шилжүүлэх боломжтой.
                        </p>
                        <h2 id="p5">Нууц үгээ хэрхэн солих</h2>
                        <p>www.sodcard.mn сайтаар болон гар утасны апп-аар нэвтэрч орсноор хэрэглэгчийн тохиргоо хэсэгт та хуучин дөрвөн оронтой нууц дугаар мөн шинээр үүсгэх дөрвөн оронтой нууц дугаарыг давтан оруулсанаар таны нууц код шинэчлэгдэнэ.</p>
                    </div>
                </div>
            </div>
        );
    }
}   

export default Help;