
export const URL_BACKEND_ROOT = 'http://49.0.191.244:8082/';
export const URL_BACKEND_REGISTER = URL_BACKEND_ROOT+'/mobile-api/register';
export const URL_BACKEND_REGISTER_CONFIRM = URL_BACKEND_ROOT+'/mobile-api/confirmRegistration';
export const URL_BACKEND_ISALIVE = URL_BACKEND_ROOT+'mobile-api/actuator/info';
export const URL_BACKEND_OAUTH_TOKEN = URL_BACKEND_ROOT+'uaa/oauth/token';
export const URL_BACKEND_USER = URL_BACKEND_ROOT+'mobile-api/me';
export const URL_BACKEND_LOCATION = URL_BACKEND_ROOT+'mobile-api/userLocations';
export const URL_BACKEND_IMAGE_GET = URL_BACKEND_ROOT+'mobile-api/file/d/';
export const URL_BACKEND_IMAGE_UPLOAD = URL_BACKEND_ROOT+'mobile-api/file/uploadProfilePicture';
export const URL_BACKEND_RESTORE_PASS = URL_BACKEND_ROOT+'mobile-api/resetPassword';
export const URL_BACKEND_CHANGE_PASS = URL_BACKEND_ROOT+'mobile-api/changePassword';
export const URL_BACKEND_TRANSACTION_LIST = URL_BACKEND_ROOT+'mobile-api/getTransactions';
export const URL_BACKEND_NOTIFICATION_LIST = URL_BACKEND_ROOT+'mobile-api/getNotifications';
export const URL_BACKEND_CARDINFO = URL_BACKEND_ROOT+'mobile-api/getCardInfo';
export const URL_BACKEND_READ_NOTIFICATION = URL_BACKEND_ROOT+'mobile-api/readNotification';
export const URL_BACKEND_FUEL_STAIONS_DETAIL = URL_BACKEND_ROOT+'mobile-api/getBranchs';
export const URL_BACKEND_MAKE_TRANSACTION_SEND = URL_BACKEND_ROOT+'mobile-api/transferBonus';
export const URL_BACKEND_GET_BANNER = URL_BACKEND_ROOT+'mobile-api/bannerByLayout';
export const URL_BACKEND_SEND_BANNER_STATS = URL_BACKEND_ROOT+'mobile-api/sendBannerStatistics';
export const URL_BACKEND_SEND_TRANSACTION_FEED = URL_BACKEND_ROOT+'mobile-api/sendTransactionFeedback';
export const URL_BACKEND_CAR_LIST = URL_BACKEND_ROOT+'mobile-api/';

export const BASIC_USERNAME = 'web';
export const BASIC_PASSWORD = 'dfdsdf';

export const HEADER_AUTH = 'Authorization';
export const HEADER_CONTENT = 'Content-Type';
export const HEAD_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8';
export const HEAD_JSON = 'application/json';
export const HEAD_MULTIPART = 'multipart/form-data';
export const AUTH_BASIC = 'basic';
export const AUTH_BEARER = 'bearer';
export const GRANT_ACCESS_TOKEN = 'password';
export const GRANT_REFRESH_TOKEN = 'refresh_token';
export const PARAM_SCOPE = 'scope';
export const PARAM_USERNAME = 'username';
export const PARAM_PASSWORD = 'password';
export const PARAM_GRANT = 'grant_type';
export const PARAM_REFRESH_TOKEN = 'refresh_token';

export const STORAGE_ACCESS_TOKEN = 'access_token';
export const STORAGE_REFRESH_TOKEN = 'refresh_token';
