import React, { Component, useContext } from 'react';

class NotFound extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="notfound">
                <h1>404</h1>
                <p>Та юу хайж байна?</p>
            </div>
        );
    }
}

export default NotFound;