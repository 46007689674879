import * as Type from '../actions/types';

const INITIAL_STATE = {
    status: Type.USER_UNK,
    transactionStatus: '',
    message: '',
    data: {},
    imgSrc: '',
    cardData: {},
    notificationList: [],
    transactionList: [],
    fuelstationList: [],
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case Type.USER_AUTHED:
            return { ...state, status: Type.USER_AUTHED }
        case Type.USER_REGISTERED:
            return { ...state, status: Type.USER_REGISTERED }
        case Type.USER_REGISTER_CONFIRMED: 
            return { ...state, status: Type.USER_REGISTER_CONFIRMED }
        case Type.USER_LOADED: 
            return { ...state, status: Type.USER_LOADED, data: action.payload, message: '' };
        case Type.USER_LOADING:
            return { ...state, status: Type.USER_LOADING, message: '' };
        case Type.USER_LOGOUT:
            return { ...state, status: Type.USER_LOGOUT, message: '' };
        case Type.USER_RECOVERED:
            return { ...state, status: Type.USER_RECOVERED, message: action.payload };
        case Type.USER_RECOVER_FAIL: 
            return { ...state, status: Type.USER_RECOVER_FAIL, message: action.payload };
        case Type.USER_ERROR: 
            return { ...state, status: Type.USER_ERROR, message: action.payload };
        case Type.USER_CARD_LOADED: 
            return { ...state, status: Type.USER_CARD_LOADED, cardData: action.payload};
        case Type.USER_IMG_LOADED:
            return { ...state, status: Type.USER_IMG_LOADED };
        case Type.USER_TRANSACTION_LOADING: 
            return { ...state, transactionStatus: Type.USER_TRANSACTION_LOADING };
        case Type.USER_TRANSCATION_LOADED:
            return { ...state, transactionStatus: Type.USER_TRANSCATION_LOADED, transactionList: action.payload };
        case Type.USER_NOTIFICATION_LOADED:
            return { ...state, notificationList: action.payload };
        case Type.MAP_LOADED: 
            return { ...state, fuelstationList: action.payload };
        case Type.MAP_ERROR: 
            return { ...state };
        case 'reset':
            return INITIAL_STATE;
        default: return state;
    }
}