
export const USER_UNK = 'user_unk';
export const USER_REGISTERED = 'user_registered';
export const USER_REGISTER_CONFIRMED = 'user_register_confirmed';
export const USER_LOADING = 'user_loading';
export const USER_AUTHED = 'user_authenticated';
export const USER_LOADED = 'user_loaded';
export const USER_ERROR = 'user_error';
export const USER_RECOVERED = 'user_recover';
export const USER_RECOVER_FAIL = 'user_recover_fail';
export const USER_LOGOUT = 'user_logout';

export const USER_CARD_LOADED = 'user_card_loaded';
export const USER_IMG_LOADED = 'user_img_loaded';
export const USER_TRANSACTION_LOADING = 'user_transcation_loading';
export const USER_TRANSCATION_LOADED = 'user_transcation_loaded';
export const USER_NOTIFICATION_LOADING = 'user_notification_loading';
export const USER_NOTIFICATION_LOADED = 'user_notification_loaded';
export const USER_FUELSTATIONS_LOADING = 'user_fuelstations_loading';
export const USER_FUELSTAIIONS_LOADED = 'user_fuelstations_loaded';

export const TRANSFER_LOADING = 'transfer_loading';
export const TRANSFER_SUCCESSFUL = 'transfer_success';
export const TRANSFER_ERROR = 'transfer_error';

export const PASS_LOADING = 'pass_loading';
export const PASS_LOADED = 'pass_loaded';
export const PASS_ERROR = 'pass_error'

export const MAP_ERROR = 'map_error';
export const MAP_LOADED = 'map_loaded';