import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Language from '../../language';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom'
import { login, restorePassword } from '../../actions/UserActions';
import * as Type from '../../actions/types';

class LoginPop extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoginModalOpen: false,
            isRestoreModelOpen: false,
            isRestoredModalOpen: false,
            phoneInput: '',
            passwordInput: '',
            registerInput: '',
            restoreErrorMessage: '',
            restoreSuccessMessage: '',
        }

        this.openLoginModal = this.openLoginModal.bind(this);
        this.openRestoreModal = this.openRestoreModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onRestore = this.onRestore.bind(this);
    }

    validatePhoneInput(input) {
        if(!isNaN(input)) {
            if(input.toString().length < 9) {
                return true
            }
        }
    }

    validatePassword(input) {
        if(!isNaN(input)) {
            if(input.toString().length < 5) {
                return true
            }
        }
    }

    validateRegisterNo(input) {
        if(!isNaN(input)) {
            if(input.toString().length < 11) {
                return true
            }
        }
    }

    // getSnapshotBeforeUpdate(nextProps, prevState) {
    //     if(nextProps.userStatus == Type.USER_RECOVERED) {
    //         return {
    //             isRestoredModalOpen: true
    //         }
    //     } else if(nextProps.userStatus == Type.USER_RECOVER_FAIL) {
    //         return {
    //             isRestoredModalOpen: true
    //         }
    //     }
    //     return null;
    // }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (nextProps.userStatus !== this.props.userStatus) {
            if(nextProps.userStatus === Type.USER_RECOVERED) {
                this.setState({ 
                    isRestoreModelOpen: false,
                    isLoginModalOpen: false,
                    isRestoredModalOpen: true,
                    restoreErrorMessage: '',
                    restoreSuccessMessage: nextProps.userMessage
                });
            } else if(nextProps.userStatus === Type.USER_RECOVER_FAIL) {
                this.setState({ 
                    isRestoreModelOpen: false,
                    isLoginModalOpen: false,
                    isRestoredModalOpen: true ,
                    restoreSuccessMessage: '',
                    restoreErrorMessage: nextProps.userMessage
                });
            }
        }
      }

    openLoginModal() {
        this.setState({isLoginModalOpen: true, isRestoreModelOpen: false, isRestoredModalOpen: false});
    }

    openRestoreModal() {
        this.setState({isLoginModalOpen: false, isRestoreModelOpen: true, isRestoredModalOpen: false});
    }

    closeModal() {
        this.setState({isLoginModalOpen: false, isRestoreModelOpen: false, isRestoredModalOpen: false, restoreErrorMessage: '', restoreSuccessMessage: ''});
    }

    onLogin() {
        this.props.login({ phone_number: this.state.phoneInput, password: this.state.passwordInput });
    }

    onRestore () {
        this.props.restorePassword({ phone: this.state.phoneInput, registerNo: this.state.registerInput });
    }

    render() {
        const { userStatus, userMessage, userData } = this.props;
        var BUTTON = <button onClick={this.onLogin} className="sod-btn sod-btn-orange sod-btn-large hvr-bounce-out">{Language.BTN_LOGIN}</button>;
        if(userStatus === Type.USER_LOADING) {
            BUTTON = <button className="sod-btn sod-btn-orange sod-btn-large hvr-bounce-out">{Language.BTN_LOADING}</button>;
        } else if(userStatus === Type.USER_AUTHED) {
            return <Redirect to='/dashboard'/>
        }

        const modalStyle = {
            modal: {
                width: '300px',
                backgroundColor: '#fff'
            }
        };

        return (
            <span>
                <div onClick={this.openLoginModal} className="nav-resp-btn hvr-fade">
                    <FontAwesomeIcon size="2x" icon={faList} color="#fff"/>
                </div>
                <button onClick={this.openLoginModal} className="nav-btn sod-btn sod-btn-medium hvr-fade">
                    {Language.BTN_LOGIN}
                </button>
                <Modal
                    open={this.state.isLoginModalOpen}
                    onClose={this.closeModal}
                    styles={modalStyle}
                >
                    <div className="sod-modal-body">
                        <h1>{Language.LOGIN_TITLE}</h1>
                        <p>{Language.LOGIN_DESC}</p>
                        <div className="sod-modal-input-wrapper">
                            <input 
                                type="text" 
                                value={this.state.phoneInput} 
                                onChange={e => {
                                    if(this.validatePhoneInput(e.target.value)) {
                                        this.setState({ phoneInput: e.target.value });
                                    }
                                }}
                                className="sod-input" 
                                placeholder={Language.INPUT_PHONE} />
                            ({Language.INPUT_PHONE_DESC})
                            <input 
                                type="password" 
                                value={this.state.passwordInput} 
                                onChange={e => {
                                    if(this.validatePassword(e.target.value)) {
                                        this.setState({ passwordInput: e.target.value })
                                    }
                                }}
                                className="sod-input" 
                                placeholder={Language.INPUT_PASSWORD} />
                            ({Language.INPUT_PASSWORD_DESC})
                        </div>
                        <span className="error-msg">{userMessage}</span>
                        <div style={{marginTop: '35px', marginBottom: '20px'}}>
                            {BUTTON}
                        </div>
                        <div>
                            <a href="#" onClick={this.openRestoreModal}>{Language.BTN_FORGET}</a>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={this.state.isRestoreModelOpen}
                    onClose={this.closeModal}
                    styles={modalStyle}
                >
                    <div className="sod-modal-body">
                        <h1>{Language.RECOVER_TITLE}</h1>
                        <p>{Language.RECOVER_DESC}</p>
                        <div className="sod-modal-input-wrapper">
                            <input 
                                value={this.state.phoneInput} 
                                onChange={e => {
                                    if(this.validatePhoneInput(e.target.value)) {
                                        this.setState({ phoneInput: e.target.value })
                                    }
                                }}
                                className="sod-input" 
                                placeholder={Language.INPUT_PHONE} />
                            ({Language.INPUT_PHONE})
                            <input 
                                value={this.state.registerInput} 
                                onChange={e => {
                                    if(this.validateRegisterNo(e.target.value)) {
                                        this.setState({ registerInput: e.target.value })
                                    }
                                }}
                                className="sod-input" 
                                placeholder={Language.INPUT_REGISTER} />
                            ({Language.INPUT_REGISTER})
                        </div>
                        <div style={{marginTop: '35px', marginBottom: '20px'}}>
                            <button onClick={this.onRestore} className="sod-btn sod-btn-orange sod-btn-large hvr-bounce-out">{Language.BTN_RECOVER}</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={this.state.isRestoredModalOpen}
                    onClose={this.closeModal}
                    styles={modalStyle}
                >
                    <div className="sod-modal-body">
                        <h1>{Language.RECOVER_TITLE}</h1>
                        <p><span className="error-msg" style={{fontSize: 18}}>{this.state.restoreErrorMessage}</span></p>
                        <p><span className="success" style={{fontSize: 18}}>{this.state.restoreSuccessMessage}</span></p>
                    </div>
                </Modal>
            </span>
        );
    }
}

const mapState2Props = state => ({
    userStatus: state.user.status,
    userData: state.user.data,
    userMessage: state.user.message
});

export default connect(mapState2Props, { login, restorePassword })(LoginPop);