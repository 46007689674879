import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { isUserLoggedIn, IMAGE_BUFFER } from '../util/SodUtility';
import * as Conf from '../config';
import * as Type from '../actions/types';
import { getMe, newPassword } from '../actions/UserActions';
import Language from '../language';

class Settings extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tabInfo: true,
            tabPassword: false,
            password_old: '',
            password_new: '',
            password_new_re: '',
            errorMsg: '',
            successMsg: '',
        }

        if(isUserLoggedIn()) {
            this.props.getMe({ 
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN)
            });
        }

        this.onTabInfo = this.onTabInfo.bind(this);
        this.onTabPassword = this.onTabPassword.bind(this);
        this.onNewPassword = this.onNewPassword.bind(this);
    }

    static getSnapshotBeforeUpdate(nextProps, prevState) {
        const successMessage = nextProps.successMessage;
        const errorMessage = nextProps.errorMessage;

        if(successMessage != null) {
            if(successMessage.length > 0) {
                return {
                    successMsg: successMessage,
                    errorMsg: '',
                };
            }
        } else if(errorMessage != null) {
            if(errorMessage.length > 0) {
                return {
                    successMsg: '',
                    errorMsg: errorMessage
                };
            }
        } else return null;
    }

    onTabInfo() {
        this.setState({ tabInfo: true, tabPassword: false });
    }

    onTabPassword() {
        this.setState({ tabInfo: false, tabPassword: true });
    }

    onSaveProfile() {
        
    }

    onNewPassword() {
        if(this.state.password_new !== this.state.password_new_re) {
            this.setState({ errorMsg: 'Шинэ нууц үгээ алдаагүй давтаж оруулна уу' });
        } else {
            this.props.newPassword({
                bearerToken: localStorage.getItem(Conf.STORAGE_ACCESS_TOKEN), 
                refreshToken: localStorage.getItem(Conf.STORAGE_REFRESH_TOKEN),
                old_password: this.state.password_old,
                new_password: this.state.password_new,
            });
        }
    }

    render() {
        return (
            <div>
                {this.renderTabHeader()}
                {this.renderTabInfo()}
                {this.renderTabPassword()}
            </div>
        );
    }

    renderTabHeader() {
        var infoActive = '';
        var passwordActive = ''
        if(this.state.tabInfo) { infoActive = 'active-on'; }
        if(this.state.tabPassword) { passwordActive = 'active-on'; }

        return (
            <div className="tab-container">
                <div className={`tab-btn ${infoActive}`} onClick={this.onTabInfo}>{Language.BTN_CHANGEINFO}</div>
                <div className={`tab-btn ${passwordActive}`} onClick={this.onTabPassword}>{Language.BTN_CHANGEPASS}</div>
            </div>
        );
    }

    renderTabInfo() {
        const userData = this.props.userData;
        if(this.state.tabInfo) {
            return (
                <div className="settings-container">
                    <div className="password-change-container sod-modal-input-wrapper">
                        <div className="sod-input-llabel">{Language.LABEL_LNAME}</div>
                        <input 
                            className="sod-input-large"
                            type="text"
                            value={userData.firstname}
                            readOnly
                        />
                        <div className="sod-input-llabel">{Language.LABEL_FNAME}</div>
                        <input 
                            className="sod-input-large"
                            type="text"
                            value={userData.lastname}
                            readOnly
                        />
                        <div className="sod-input-llabel">{Language.LABEL_BDAY}</div>
                        <input 
                            className="sod-input-large"
                            type="text"
                            value={userData.birthday}
                            readOnly
                        />
                        <div className="sod-input-llabel">{Language.LABEL_GANDER}</div>
                        <input 
                            className="sod-input-large"
                            type="text"
                            value={this.renderGender(userData.gender)}
                            readOnly
                        />
                    </div>
                </div>
            );
        } else return null;
    }

    renderGender(num) {
        if(num === 1) {
            return "Эрэгтэй";
        } else return "Эмэгтэй";
    }

    renderTabPassword() {
        if(this.state.tabPassword) {
            return (
                <div className="settings-container">
                    <div className="password-change-container sod-modal-input-wrapper">
                        <div className="sod-input-llabel">{Language.INPUT_OLD_PASSWORD}</div>
                        <input 
                            className="sod-input-large"
                            type="password"
                            value={this.state.password_old}
                            onChange={e => this.setState({ password_old: e.target.value })} 
                            placeholder={Language.INPUT_OLD_PASSWORD}
                        />
                        ({Language.INPUT_PASSWORD_DESC})
                        <div className="sod-input-llabel">{Language.INPUT_NEW_PASSWORD}</div>
                        <input 
                            className="sod-input-large"
                            type="password"
                            value={this.state.password_new}
                            onChange={e => this.setState({ password_new: e.target.value })} 
                            placeholder={Language.INPUT_NEW_PASSWORD}
                        />
                        ({Language.INPUT_PASSWORD_DESC})
                        <div className="sod-input-llabel">{Language.INPUT_NEW_RE_PASSWORD}</div>
                        <input 
                            className="sod-input-large"
                            type="password"
                            value={this.state.password_new_re}
                            onChange={e => this.setState({ password_new_re: e.target.value })} 
                            placeholder={Language.INPUT_NEW_RE_PASSWORD}
                        />
                        ({Language.INPUT_PASSWORD_DESC})
                        <div className="settings-container" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                            <span style={{fontSize:'16px'}} className="warning">{this.state.errorMsg}</span>
                            <span style={{fontSize:'16px'}} className="success">{this.state.successMsg}</span>
                            <button onClick={this.onNewPassword} className="sod-btn sod-btn-orange sod-btn-large">{Language.BTN_SAVE}</button>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
}

const mapState2Props = state => ({
    userData: state.user.data,
    successMessage: state.password.successMessage,
    errorMessage: state.password.errorMessage,
});


export default connect(mapState2Props, { getMe, newPassword })(Settings);