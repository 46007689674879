import React, { useContext } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'hover.css';
import '../../assets/styles/SodStyle.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab);

const Template = props => {

    return (
        <main>
            <Header/>
            <div style={{backgroundColor: '#fff'}}>
                {props.children}
            </div>
            <Footer/>
        </main>
    )
}

export default Template;