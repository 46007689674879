import React, { Component } from 'react';
import Modal from 'react-modal';
import Language from '../../language';
import { Link } from 'react-router-dom';

Modal.setAppElement(document.getElementById('root'));

class RegisterPop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRegisterModalOpen: false
        }

        this.openRegisterModal = this.openRegisterModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openRegisterModal() {
        this.setState({isRegisterModalOpen: true});
    }

    closeModal() {
        this.setState({isRegisterModalOpen: false});
    }

    render() {
        return(
            <span>
                <Link to="/register">
                    <button className="sod-btn sod-btn-orange sod-btn-large hvr-grow">
                        <span>{Language.BTN_REGISTER}</span>
                    </button>
                </Link>
                <Modal
                    isOpen={this.state.isRegisterModalOpen}
                    onAfterOpen={this.afterOpenLoginModal}
                    onRequestClose={this.closeModal}
                    style={modalStyle}
                >
                    <div className="sod-modal-body">
                        <h1>{Language.REGISTER_TITLE}</h1>
                        <p>{Language.REGISTER_DESC}</p>
                    </div>
                </Modal>
            </span>
        );
    }
}

const modalStyle = {
    content: {
        zIndex: '3',
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom :'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '300px',
        height: 'auto',
    }
};

export default RegisterPop;