import * as Type from './types';
import * as Conf from '../config';
import Language from '../language';

export const restorePassword = ({ phone, registerNo }) => (dispatch) => {
    dispatch({ type: Type.USER_LOADING });
    fetch(Conf.URL_BACKEND_RESTORE_PASS, {
        method: 'POST',
        mode: 'cors',
        headers: {
            [Conf.HEADER_CONTENT]: Conf.HEAD_JSON
        },
        body: JSON.stringify({
            phoneNo: phone,
            regNo: registerNo
        })
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else {
            dispatch({
                type: Type.USER_ERROR,
                payload: 'Incorrect or network error'
            });
            return null;
        }
    }).then(data => {
        if(data != null) {
            if(data.status == 0) {
                dispatch({
                    type: Type.USER_RECOVERED,
                    payload: data.msg
                });
            } else if(data.status == 1) {
                dispatch({
                    type: Type.USER_RECOVER_FAIL,
                    payload: data.msg
                });
            }
        }
    });
}

export const login = ({ phone_number, password }) => (dispatch) => {
    var details = {
        [Conf.PARAM_SCOPE]: 'auth',
        [Conf.PARAM_GRANT]: Conf.GRANT_ACCESS_TOKEN,
        [Conf.PARAM_USERNAME]: phone_number,
        [Conf.PARAM_PASSWORD]: password
    };
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let headers = new Headers();
    headers.append(Conf.HEADER_AUTH, `${Conf.AUTH_BASIC} ${btoa(`${Conf.BASIC_USERNAME}:${Conf.BASIC_PASSWORD}`)}`);
    headers.append(Conf.HEADER_CONTENT, Conf.HEAD_URLENCODED);

    dispatch({ type: Type.USER_LOADING });
    fetch(Conf.URL_BACKEND_OAUTH_TOKEN, {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: formBody,
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401 || response.status === 400) {
            dispatch({
                type: Type.USER_ERROR,
                payload: Language.ERROR_LOGIN_BAD_CREDENTIAL,
            })
            return null;
        } else {
            dispatch({
                type: Type.USER_ERROR,
                payload: Language.ERROR_NETWORK,
            });
            return null;
        }
    }).then(data => {
        if(data != null) {
            console.log(data);
            localStorage.setItem(Conf.STORAGE_ACCESS_TOKEN, data.access_token);
            localStorage.setItem(Conf.STORAGE_REFRESH_TOKEN, data.refresh_token);
            dispatch({
                type: Type.USER_AUTHED,
            });
        }
    });
}

function getNewAccessToken(refreshToken) {
    var details = {
        [Conf.PARAM_SCOPE]: 'auth',
        [Conf.PARAM_GRANT]: Conf.GRANT_REFRESH_TOKEN,
        [Conf.PARAM_REFRESH_TOKEN]: refreshToken
    };
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let headers = new Headers();
    headers.append(Conf.HEADER_AUTH, `${Conf.AUTH_BASIC} ${btoa(`${Conf.BASIC_USERNAME}:${Conf.BASIC_PASSWORD}`)}`);
    headers.append(Conf.HEADER_CONTENT, Conf.HEAD_URLENCODED);

    return fetch(Conf.URL_BACKEND_OAUTH_TOKEN, {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: formBody,
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            return null;
        } else {
            return null;
        }
    }).then(data => {
        if(data != null) {
            console.log("REFRESH_TOKEN");
            localStorage.setItem(Conf.STORAGE_ACCESS_TOKEN, data.access_token);
            localStorage.setItem(Conf.STORAGE_REFRESH_TOKEN, data.refresh_token);
            return data;
        }
    });
}

export const getMe = ({ bearerToken, refreshToken }) => (dispatch) => {
    fetch(Conf.URL_BACKEND_USER, {
        method: 'GET',
        mode: 'cors',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(getMe({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken }));
                }
            );
            // console.log(newToken);
            // return dispatch(getMe({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken }));
        } else {
            return null;
        }
    }).then(data => {
        dispatch({
            type: Type.USER_LOADED,
            payload: data
        });
        // getImage({ bearerToken: bearerToken, refreshToken: refreshToken, uuid: data.picUuid });
    });
}

export const cardInfo = ({ bearerToken, refreshToken }) => (dispatch) => {
    fetch(Conf.URL_BACKEND_CARDINFO, {
        method: 'GET',
        mode: 'cors',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(cardInfo({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken }));
                }
            );
        } else {
            return null;
        }
    }).then(data => {
        if(data.status == 0) {
            dispatch({
                type: Type.USER_CARD_LOADED,
                payload: data
            });
        }
    });
}

export const getImage = ({ bearerToken, refreshToken, uuid }) => (dispatch) => {
    fetch(`${Conf.URL_BACKEND_IMAGE_GET}${uuid}`, {
        method: 'GET',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.blob();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(getImage({ bearerToken: newToken.access_token, refreshToken: newToken.refreshTokenm, uuid: uuid }))
                }
            )
            return 
        } else {
            return null;
        }
    }).then(data => {
        var outside = URL.createObjectURL(data);
        console.log(outside);
        dispatch({
            type: Type.USER_IMG_LOADED
        });
    });
}

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
};

export const getTransactionList = ({ bearerToken, refreshToken, page, size }) => (dispatch) => {
    dispatch({
        type: Type.USER_TRANSACTION_LOADING,
    });
    fetch(`${Conf.URL_BACKEND_TRANSACTION_LIST}?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(getTransactionList({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken, page: page, size: size }))
                }
            );
        } else {
            return null;
        }
    }).then(data => {
        if(data.status == 0) {
            dispatch({
                type: Type.USER_TRANSCATION_LOADED,
                payload: data.ret,
            });
        }
    });
}

export const getNotificationList = ({ bearerToken, refreshToken, page, size }) => (dispatch) => {
    fetch(`${Conf.URL_BACKEND_NOTIFICATION_LIST}?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        console.log(response.status);
        console.log(response.body);
        if(response.status === 200 || response.status === 202) {

        } else if(response.status === 401) {
            var newToken = getNewAccessToken(refreshToken);
            return getNotificationList({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken });
        } else {
            return null;
        }
    }).then(data => {
        dispatch({
            type: ''
        });
    });
}

export const getFuelStations = ({ bearerToken, refreshToken }) => (dispatch) => {
    fetch(Conf.URL_BACKEND_FUEL_STAIONS_DETAIL, {
        method: 'GET',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`
        }
    }).then(response => {
        if(response.status === 200 || response.status == 202) {
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(getFuelStations({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken }));
                }
            );
            return;
        } else {
            dispatch({
                type: Type.MAP_ERROR,
            });
            return;
        }
    }).then(data => {
        if(data.status == 0) {
            dispatch({
                type: Type.MAP_LOADED,
                payload: data.branchDtoList,
            });
        }
    });
}

export const newPassword = ({ bearerToken, refreshToken, old_password, new_password }) => (dispatch) => {
    fetch(Conf.URL_BACKEND_CHANGE_PASS, {
        method: 'POST',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`,
            [Conf.HEADER_CONTENT]: [Conf.HEAD_JSON]
        },
        body: JSON.stringify({
            oldPassword: old_password,
            newPassword: new_password,
        })
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(newPassword({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken, old_password: old_password, new_password: new_password }));
                }
            );
            return;
        } else if(response.status === 500) {
            dispatch({
                type: Type.PASS_ERROR,
                payload: 'Системд саатал гарсан байгаа тул та дараа дахин оролдоно уу'
            });
            return;
        } else {
            dispatch({
                type: Type.PASS_ERROR,
                payload: 'Системд саатал гарсан байгаа тул та дараа дахин оролдоно уу'
            });
            return;
        }
    }).then(data => {
        if(data.status == 0) {
            dispatch({
                type: Type.PASS_LOADED,
                payload: data.msg
            });
        } else {
            dispatch({
                type: Type.PASS_ERROR,
                payload: data.msg
            });
        }
    })
}

export const logout = () => (dispatch) => {
    localStorage.removeItem(Conf.STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(Conf.STORAGE_REFRESH_TOKEN);
    dispatch({
        type: Type.USER_LOGOUT
    });
}

export const resetStatus = () => (dispatch) => {
    dispatch({
        type: 'reset'
    });
}

export const register = ({
    phoneNo, firstName, pinCode, rePinCode, emailAddress, referralCode
}) => (dispatch) => {
    fetch(Conf.URL_BACKEND_REGISTER ,{
        method: 'POST',
        headers: {
            [Conf.HEADER_CONTENT]: [Conf.HEAD_JSON]
        },
        body: JSON.stringify({
            phoneNo, firstName, pinCode, rePinCode, emailAddress, referralCode
        }),
    }).then(response => {
        if(response.status == 200 || response.status == 202) {
            return response.json();
        } else {
            dispatch({
                type: Type.USER_ERROR,
                payload: 'Холболтийн алдаа'
            });
        }
    }).then(data => {
        console.log('===========gg=========');
        console.log(data.status);
        if(data.status == 1) {
            console.log(data);
            dispatch({
                type: Type.USER_ERROR,
                payload: data.msg
            });
        } else {
            dispatch({
                type: Type.USER_REGISTERED,
                payload: data
            });
        }
    });
}

export const registerConfirm = ({
    phoneNo, confirmNo
}) => (dispatch) => {
    fetch(Conf.URL_BACKEND_REGISTER_CONFIRM, {
        method: 'POST',
        headers: {
            [Conf.HEADER_CONTENT]: [Conf.HEAD_JSON]
        },
        body: JSON.stringify({
           phoneNo, confirmNo 
        }),
    }).then(response => {
        if(response.status == 200 || response.status == 202) {
            return response.json();
        } else {
            dispatch({
                type: Type.USER_ERROR,
                payload: 'Холболтийн алдаа'
            });
        }
    }).then(data => {
        console.log(data);
        if(data.status == 1) {
            dispatch({
                type: Type.USER_ERROR,
                payload: data.msg
            })
        } else {
            dispatch({
                type: Type.USER_REGISTER_CONFIRMED,
                payload: data
            });
        }
        
    });
}

