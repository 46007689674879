import * as Type from './types';
import * as Conf from '../config';
import Language from '../language';

export const transferBalance = ({ bearerToken, refreshToken, phoneNo, transferAmount }) => (dispatch) => {
    
    console.log('========Trasfer amount===========');
    console.log(transferAmount);
    var stateOfRequest = 0;
    dispatch({
        type: Type.TRANSFER_LOADING
    });
    fetch(Conf.URL_BACKEND_MAKE_TRANSACTION_SEND, {
        method: 'POST',
        headers: {
            [Conf.HEADER_AUTH]: `${Conf.AUTH_BEARER} ${bearerToken}`,
            [Conf.HEADER_CONTENT]: Conf.HEAD_JSON,
        },
        body: JSON.stringify({
            toPhoneNo: phoneNo,
            transferAmount: transferAmount,
        }),
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            stateOfRequest = 200;
            return response.json();
        } else if(response.status === 500) {
            stateOfRequest = 500;
            return response.json();
        } else if(response.status === 401) {
            getNewAccessToken(refreshToken).then(
                newToken => {
                    dispatch(transferBalance({ bearerToken: newToken.access_token, refreshToken: newToken.refreshToken, phoneNo: phoneNo, transferAmount: transferAmount }));
                }
            );
            stateOfRequest = 401;
            return response.json();
        } else if(response.status === 400) {
            stateOfRequest = 400
            return response.json();
        }
    }).then(data => {
        var dispatchingType = '';
        var dispatchingPayload = '';

        if(stateOfRequest == 200) {
            if(data.status == 1) {
                dispatchingType = Type.TRANSFER_ERROR;
                dispatchingPayload = data.msg;
            } else if(data.status == 0) {
                dispatchingType = Type.TRANSFER_SUCCESSFUL;
                dispatchingPayload = data.msg;
            }
        } else if(stateOfRequest == 401) {
            dispatchingType = Type.TRANSFER_ERROR;
        } else if(stateOfRequest == 500) {
            dispatchingType = Type.TRANSFER_ERROR;
            dispatchingPayload = data.msg;
        } else if(stateOfRequest == 0) {
            dispatchingType = Type.TRANSFER_ERROR;
            dispatchingPayload = 'Холболтийн алдаа';
        }
        
        dispatch({
            type: dispatchingType,
            payload: dispatchingPayload,
        });
    });
}

function getNewAccessToken(refreshToken) {
    var details = {
        [Conf.PARAM_SCOPE]: 'auth',
        [Conf.PARAM_GRANT]: Conf.GRANT_REFRESH_TOKEN,
        [Conf.PARAM_REFRESH_TOKEN]: refreshToken
    };
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let headers = new Headers();
    headers.append(Conf.HEADER_AUTH, `${Conf.AUTH_BASIC} ${btoa(`${Conf.BASIC_USERNAME}:${Conf.BASIC_PASSWORD}`)}`);
    headers.append(Conf.HEADER_CONTENT, Conf.HEAD_URLENCODED);

    return fetch(Conf.URL_BACKEND_OAUTH_TOKEN, {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: formBody,
    }).then(response => {
        if(response.status === 200 || response.status === 202) {
            return response.json();
        } else if(response.status === 401) {
            return null;
        } else {
            return null;
        }
    }).then(data => {
        if(data != null) {
            localStorage.setItem(Conf.STORAGE_ACCESS_TOKEN, data.access_token);
            localStorage.setItem(Conf.STORAGE_REFRESH_TOKEN, data.refresh_token);
            return data;
        }
    });
}
